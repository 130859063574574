<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/HDF.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/HDF-N_Annotations/'"
                    [imageWidth]="3069"
                    [imageHeight]="3069" [imageScale]="0.495"
                    [watermark]="false"
                    [information]="false"
                    [filterExposures]="[]"
                    [nights]="{ count: 10, start: '1995-12-18', end: '1995-12-28' }"
                    [equipment]="{  mounts: [],
                                    telescopes: [ 'Hubble Space Telescope' ],
                                    cameras: [ 'WFPC2' ] }">
</app-astro-photo>
