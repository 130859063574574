<div *ngIf="information" id="info-overlay" class="text-overlay containerX">
    <div id="info-overlay-content" class="Xw-100 Xh-100 Xmax-vw-100">
        <div class="row">
            <div class="col-12">
                <figure class="text-light p-4"
                    style="border-left: .35rem solid #fcdb5e; border-top: 1px solid #eee; border-right: 1px solid #eee; border-bottom: 1px solid #eee;">
                    <button type="button" class="btn text-white float-end header-close-button"><i class="fa fa-times-circle" style="font-size: 2.5rem;" aria-label="Close"></i></button>
                    <blockquote>
                        <p *ngFor="let tagline of imageTaglines;" [innerHTML]="tagline"></p>
                        <!-- <p *ngFor="let tagline of getTaglines();" [innerHTML]="tagline"></p> -->
                    </blockquote>
                    <figcaption *ngIf="astrophoto.sources != undefined && astrophoto.sources.length > 0" class="blockquote-footer mb-0">
                        <p>sources:<br/>
                            <cite>
                                <a *ngFor="let source of astrophoto.sources;" [attr.href]="source.url" target="_blank">
                                    {{ source.text }}<br/>
                                </a>
                            </cite>
                        </p>
                    </figcaption>
                    <figcaption *ngIf="astrophoto.links != undefined && astrophoto.links.length > 0" class="blockquote-footer mb-0">
                        <p>links:<br/>
                            <cite>
                                <a *ngFor="let link of astrophoto.links;" [attr.href]="link.url" target="_blank">
                                    {{ link.text }}<br/>
                                </a>
                            </cite>
                        </p>
                    </figcaption>
                </figure>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <figure id="project-card" class="text-light p-4"
                    style="border-left: .35rem solid #e96d08; border-top: 1px solid #eee; border-right: 1px solid #eee; border-bottom: 1px solid #eee;">
                    <button type="button" class="btn text-white float-end header-close-button"><i class="fa fa-times-circle" style="font-size: 2rem;" aria-label="Close"></i></button>
                    <blockquote class="mb-0">
                        <div class="row align-items-end ms-n2"> <!-- FIX ms-n2 and ps-0 because of close button -->
                            <!-- <div class="col-12 col-md-7 ps-0"> -->
                            <div class="col-auto ps-0">
                                <h2><i class="fa fa-camera astro-details"></i>&nbsp;<b>{{ getImageName() }}</b></h2>
                            </div>
                            <!-- <div class="col-12 col-md-5 text-start text-md-end ps-0"> -->
                            <div class="flex-fill col-auto text-start text-xl-end ps-0">
                                <h3><i class="fa fa-image astro-details"></i>&nbsp;{{ getImageScale() }}</h3>
                            </div>
                        </div>
                        <div class="row align-items-end mt-2">
                            <!-- <div class="col-12 col-lg-3 col-xxl-2"> -->
                            <div class="col-auto">
                                <h3>
                                    <i class="fa fa-video-camera astro-details"></i>&nbsp;
                                    <b [innerHTML]="getTotalExposure()"></b>
                                </h3>
                            </div>
                            <!-- <div class="col-12 col-lg-9 col-xxl-10 text-md-end"> -->
                            <div class="flex-fill col-auto text-md-end">
                                <h5>
                                    <i class="fa fa-filter astro-details"></i>
                                    <i *ngFor="let filterexposures of filterExposures;">
                                        &nbsp;<b>{{ filterexposures.filter }}:</b>&nbsp;{{ listExposures(filterexposures.filter) }}={{ getTotalExposure(filterexposures.filter) }}
                                    </i>
                                </h5>
                            </div>
                        </div>
                        <div class="row align-items-end mt-2">
                            <!-- <div class="col-12 col-md-6"> -->
                            <div class="col-auto">
                                <h5 *ngIf="nights.count && nights.count > 0">
                                    <i class="fa fa-calendar astro-details"></i>&nbsp;&nbsp;{{ nights.count }} <span i18n>nights</span> ({{ nights.start }} - {{ nights.end }})
                                </h5>
                                <h5 *ngIf="!(nights.count && nights.count > 0)">
                                    <i class="fa fa-calendar astro-details"></i>&nbsp;&nbsp;{{ nights.start }} - {{ nights.end }}
                                </h5>
                            </div>
                            <!-- <div class="col-12 col-md-6 text-md-end"> -->
                            <div class="flex-fill col-auto text-md-end">
                                <h5>
                                    <i class="fa fa-map-marker astro-details"></i>&nbsp;&nbsp;{{ astrophoto.location }}
                                </h5>
                            </div>
                        </div>
                        <div class="row align-items-end mt-2 justify-content-between pt-2 border-top"
                            *ngIf="(equipment.mounts && equipment.mounts.length > 0) || (equipment.telescopes && equipment.telescopes.length > 0) || (hasFilterList()) || (equipment.cameras && equipment.cameras.length > 0)">
                            <div *ngIf="equipment.mounts && equipment.mounts.length > 0" class="col-auto">
                                <p><i class="fa fa-rocket astro-details"></i>&nbsp;&nbsp;{{ equipment.mounts.join(', ') }}</p>
                            </div>
                            <div *ngIf="equipment.telescopes && equipment.telescopes.length > 0" class="col-auto">
                                <p><i class="fa fa-binoculars astro-details"></i>&nbsp;&nbsp;{{ equipment.telescopes.join(', ') }}</p>
                            </div>
                            <div *ngIf="hasFilterList()" class="col-auto">
                                <p><i class="fa fa-filter astro-details"></i>&nbsp;&nbsp;<span [innerHTML]="getFilterList()"></span></p>
                            </div>
                            <div *ngIf="equipment.cameras && equipment.cameras.length > 0" class="col-auto">
                                <p><i class="fa fa-camera-retro astro-details"></i>&nbsp;&nbsp;<span [innerHTML]="equipment.cameras.join(', ')"></span></p>
                            </div>
                        </div>
                    </blockquote>
                </figure>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <figure id="project-card" class="text-light p-4"
                    style="border-left: .35rem solid #e96d08; border-top: 1px solid #eee; border-right: 1px solid #eee; border-bottom: 1px solid #eee;">
                    <button type="button" class="btn text-white float-end header-close-button"><i class="fa fa-times-circle" style="font-size: 2.5rem;" aria-label="Close"></i></button>
                    <blockquote>
                        <h1><i class="fa fa-camera astro-details"></i>&nbsp;&nbsp;<b i18n>Technical details</b></h1>
                        <p>
                            <span *ngFor="let property of astrophoto.properties;">
                                <b>{{ property.title }}:&#8195;</b>
                                <span [innerHTML]="property.text"></span><br>
                            </span>
                        </p>
                        <ul class="">
                            <li *ngFor="let exposure of astrophoto.exposures;" class=""><b>{{ exposure.filter }}:&#8195;</b>{{ exposure.exposure }}</li>
                        </ul>
                    </blockquote>
                </figure>
            </div>
        </div> -->
    </div>
</div>
<div id="openseadragon1" style="width: 100%; flex-grow: 1;" #viewer>
</div>
<div id="tile-loading-progress"></div>
<div *ngIf="watermark" id="copyright">
    <img src="assets/img/elveteek-logo-white.png" class="offset-lg-10 col-lg-2 offset-md-8 col-md-4 offset-6 col-6" style="opacity: 0.2;"/>
</div>