<div class="container-fluid bg-dark">
    <div class="container text-light">
        <div *ngFor="let row of rows; let i = index; let odd = odd;" [attr.data-index]="i" class="pb-0">
            <div [class]="'mb-3 bg-dark border-secondary translate-in-from-' + (odd ? 'left' : 'right') + '-delayed-' + i"
                 style="opacity: 0;">
                <div class="row no-gutters justify-content-center">
                    <div *ngIf="row.imageW100 != undefined" [class]="'col-12 align-self-center ' + row.imageClass ">
                        <a *ngIf="row.imageW100RouterLink != undefined" [routerLink]="row.imageW100RouterLink">
                            <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <a *ngIf="row.imageW100Url != undefined" [href]="row.imageW100Url" target="_blank">
                            <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <img *ngIf="row.imageW100RouterLink == undefined && row.imageW100Url == undefined" src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                    </div>
                    <div *ngIf="row.image != undefined" [class]="'col-8 col-md-4 align-self-center ' + row.imageClass ">
                        <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block"
                            style="max-width: 400px">
                    </div>
                    <div [class]="row.image != undefined ? 'col-md-8' : 'col-12'" style="position: relative;">
                        <div class="card-body">
                            <h2 *ngIf="row.titleHtml != undefined" [class]="'card-title row justify-content-between ' + row.titleClass">
                                <span [innerHTML]="row.titleHtml"></span>
                            </h2>
                            <hr *ngIf="row.titleHtml != undefined" class="featurette-divider">
                            <h5 *ngIf="row.textHtml != undefined" [class]="'card-text position-relative ' + row.textClass" [innerHTML]="row.textHtml" style="z-index: 3;"></h5>
                            <div [class]="'row text-center card-img-overlay align-items-end p-4 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs pb-2">
                            </div>
                            <div [class]="'row card-img-overlay align-items-end px-2 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <div class="d-inline-flex flex-wrap gap-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'mx-1 text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'mx-1 text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div [class]="'row text-center px-2 ' + (row.textHtml != undefined ? 'd-xxl-none' : 'd-none')" style="height: 8px;">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs">
                            </div>
                            <div [class]="'row ' + (row.textHtml != undefined ? 'd-xxl-none' : '')">
                                <div class="d-inline-flex flex-wrap gap-2 px-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>