import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m31.json';

@Component({
  selector: 'app-adf-north-image',
  templateUrl: './adf-north-image.component.html',
  styleUrls: ['./adf-north-image.component.sass']
})
export class AdfNorthImageComponent {

  target: any = jsonTarget;
}
