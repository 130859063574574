import { Component, OnInit } from '@angular/core';
import jsonRows from '../../../locale/data/home.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  rows: any = jsonRows;

  constructor() { }

  ngOnInit() {
  }

}
