import { Component } from '@angular/core';
import jsonRows from '../../../locale/data/hdf.json';

@Component({
  selector: 'app-hdf',
  templateUrl: './hdf.component.html',
  styleUrls: ['./hdf.component.sass']
})
export class HdfComponent {

  rows: any = jsonRows;

}
