<div class="container-fluid bg-dark">
  <div class="container text-light">
      <div *ngFor="let row of rows; let i = index; let odd = odd;" [attr.data-index]="i" class="pb-3">
          <div [class]="'mb-3 bg-dark border-secondary translate-in-from-' + (odd ? 'left' : 'right') + '-delayed-' + i"
               style="opacity: 0;">
              <div [id]="row.id" class="row no-gutters justify-content-center" style="min-height: 240px;">
                  <div *ngIf="row.imageW100 != undefined" [class]="'col-12 align-self-center ' + row.imageClass ">
                    <a *ngIf="row.imageW100RouterLink != undefined" [routerLink]="row.imageW100RouterLink">
                        <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                    </a>
                    <a *ngIf="row.imageW100Url != undefined" [href]="row.imageW100Url" target="_blank">
                        <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                    </a>
                    <img *ngIf="row.imageW100RouterLink == undefined && row.imageW100Url == undefined" src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                  </div>
                  <div *ngIf="row.image != undefined" [class]="'col-8 col-md-4 align-self-center ' + row.imageClass ">
                      <a *ngIf="row.imageRouterLink != undefined" [routerLink]="row.imageRouterLink">
                          <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block">
                      </a>
                      <a *ngIf="row.imageUrl != undefined" [href]="row.imageUrl" target="_blank">
                          <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block">
                      </a>
                      <img *ngIf="row.imageRouterLink == undefined && row.imageUrl == undefined" src="{{row.image}}" class="col-md-2 card-img mx-auto d-block"
                          style="max-width: 400px">
                  </div>

                  <!-- <div *ngIf="row.image != undefined" [class]="'col-8 col-md-4 align-self-center ' + row.imageClass ">
                      <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block"
                          style="max-width: 400px">
                  </div> -->
                  <div [class]="row.image != undefined ? 'col-md-8' : 'col-12'" style="position: relative;">
                      <div class="card-body">
                          <h2 [class]="'card-title row justify-content-between tttext-' + (row.image != undefined ? (odd ? 'end' : 'start') : 'center') + ' ' + row.titleClass">
                              <span [innerHTML]="row.titleHtml"></span>
                          </h2>
                          <hr class="featurette-divider">
                          <h5 *ngIf="row.textHtml != undefined" [class]="'card-text position-relative text-' + (row.image != undefined ? 'start' : 'center')" [innerHTML]="row.textHtml" style="z-index: 3;"></h5>
                          <div [class]="'row text-center card-img-overlay align-items-end p-4 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                              <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs pb-2">
                          </div>
                          <div [class]="'row card-img-overlay align-items-end px-2 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                              <div class="d-inline-flex flex-wrap gap-2">
                                  <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                      <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'mx-1 text-nowrap btn ' + button.class">
                                          <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                          <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                          &nbsp;{{ button.text }}
                                      </a>
                                      <a *ngIf="button.url != undefined" [href]="button.url" [class]="'mx-1 text-nowrap btn ' + button.class" target="_blank">
                                          <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                          <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                          &nbsp;{{ button.text }}
                                      </a>
                                  </div>
                              </div>
                          </div>
                          <div [class]="'row text-center px-2 ' + (row.textHtml != undefined ? 'd-xxl-none' : 'd-none')" style="height: 8px;">
                              <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs">
                          </div>
                          <div [class]="'row ' + (row.textHtml != undefined ? 'd-xxl-none' : '')">
                              <div class="d-inline-flex flex-wrap gap-2 px-2">
                                  <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                      <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                          <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                          <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                          &nbsp;{{ button.text }}
                                      </a>
                                      <a *ngIf="button.url != undefined" [href]="button.url" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                          <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                          <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                          &nbsp;{{ button.text }}
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <br><br>
          </div>
      </div>
  </div>
</div>

<!--
<div class="container-fluid bg-dark">
  <div class="container text-light">
    <h1 class="translate-in-from-left"><b>The Amateur Deep Field (ADF) Project</b></h1>

    <div class="row translate-in-from-left-delayed-2">
      <div class="col-lg-9 d-flex align-items-stretch">
      <div class="row">
          <h5 class="d-flex align-items-center">
            2025 is the 30 th anniversary of the Hubble Deep Field image. This image profoundly
            changed the way we view the universe. With ten days of exposure of an area in space
            that appeared to be empty, the Hubble image revealed over 10,000 galaxies that no one
            knew existed. Subsequent deep field images proved that anywhere you look in space
            there are millions of very distant galaxies, all speeding away from us.
          </h5>
          <h5 class="d-flex align-items-center">
            The Hubble Deep Field image was the brainchild of Robert Williams, director of the
            Space Telescope Science Institute from 1993-1998. He is the Science Advisor on the
            Amateur Deep Field Project (ADF).
          </h5>

        </div>
      </div>
      <div class="card bg-dark col-lg-3 translate-in-from-top">
        <img class="card-img" src="assets/img/STScI-01EVTAT56XS4RN4CPSEVPM0TGF.jpg" alt="">
        <div class="card-img-overlay d-flex align-items-start justify-content-end">
          <div class="card-text d-none d-md-block ms-3">
            <p class="col-12 col-lg-12">The Hubble Deep Field Image, 1995</p>
          </div>
        </div>
      </div>
      <div class="row d-md-none">
        <div class="col">
          <p class="translate-in-from-right text-center">The Hubble Deep Field Image, 1995</p>
        </div>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-lg-8 d-flex align-items-center order-lg-1">
        <h5 class="translate-in-from-right-delayed-4">
          For the ADF, amateur astronomers around the globe are combining forces, pooling their
          data into one image, on two other “empty” spots in the sky, one in the Northern
          Hemisphere and one in the Southern. This project both commemorates the Hubble
          image and highlights the capabilities that amateurs now have. Our images are revealing
          thousands of unseen galaxies, deepening our understanding of the vastness of the
          universe in which we live.
        </h5>
      </div>
      <div class="card bg-dark col-lg-4 order-lg-0 translate-in-from-bottom">
        <img class="card-img" src="assets/img/combination_lum_60h.jpg" alt="">
        <div class="card-img-overlay d-flex align-items-start justify-content-start">
          <div class="card-text d-none d-md-block ms-3">
            <p class="col-12 col-lg-12">The Amateur Deep Field Image, North, 2024</p>
          </div>
        </div>
      </div>
      <div class="row d-md-none">
        <div class="col">
          <p class="translate-in-from-right text-center">The Amateur Deep Field Image, North, 2024</p>
        </div>
      </div>
    </div>
  </div>

</div>
-->