<div class="container-fluid bg-dark">
    <div class="container text-light">
        <div *ngFor="let row of rows; let i = index; let odd = odd;" [attr.data-index]="i" class="pb-0">
            <div [class]="'mb-3 bg-dark border-secondary translate-in-from-' + (odd ? 'left' : 'right') + '-delayed-' + i"
                 style="opacity: 0;">
                <div class="row no-gutters justify-content-center">
                    <div *ngIf="row.imageW100 != undefined" [class]="'col-12 align-self-center ' + row.imageClass ">
                        <a *ngIf="row.imageW100RouterLink != undefined" [routerLink]="row.imageW100RouterLink">
                            <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <a *ngIf="row.imageW100Url != undefined" [href]="row.imageW100Url" target="_blank">
                            <img src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <img *ngIf="row.imageW100RouterLink == undefined && row.imageW100Url == undefined" src="{{row.imageW100}}" class="col-md-2 card-img mx-auto d-block">
                    </div>
                    <div *ngIf="row.image != undefined" [class]="'col-8 col-md-4 align-self-center ' + row.imageClass ">
                        <a *ngIf="row.imageRouterLink != undefined" [routerLink]="row.imageRouterLink">
                            <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <a *ngIf="row.image != undefined" [href]="row.image" target="_blank">
                            <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block">
                        </a>
                        <img *ngIf="row.imageRouterLink == undefined && row.image == undefined" src="{{row.image}}" class="col-md-2 card-img mx-auto d-block"
                            style="max-width: 400px">
                    </div>
                    <div [class]="row.image != undefined ? 'col-md-8' : 'col-12'" style="position: relative;">
                        <div class="card-body">
                            <h2 *ngIf="row.titleHtml != undefined" [class]="'card-title row justify-content-between ' + row.titleClass">
                                <span [innerHTML]="row.titleHtml"></span>
                            </h2>
                            <hr *ngIf="row.titleHtml != undefined" class="featurette-divider">
                            <h5 *ngIf="row.textHtml != undefined" [class]="'card-text position-relative ' + row.textClass" [innerHTML]="row.textHtml" style="z-index: 3;"></h5>
                            <div [class]="'row text-center card-img-overlay align-items-end p-4 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs pb-2">
                            </div>
                            <div [class]="'row card-img-overlay align-items-end px-2 d-none ' + (row.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <div class="d-inline-flex flex-wrap gap-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'mx-1 text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'mx-1 text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div [class]="'row text-center px-2 ' + (row.textHtml != undefined ? 'd-xxl-none' : 'd-none')" style="height: 8px;">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs">
                            </div>
                            <div [class]="'row ' + (row.textHtml != undefined ? 'd-xxl-none' : '')">
                                <div class="d-inline-flex flex-wrap gap-2 px-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <br><br> -->
            </div>
        </div>
    </div>
  </div>

<!--<div class="container-fluid bg-dark">
    <div class="container text-light">
        <h1 class="translate-in-from-left"><b>The most important photograph ever taken was a picture of nothing</b></h1>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-0">
                    The Hubble Space Telescope, which for the last 16 years has orbited 600 km above our heads, is the
                    most powerful observatory ever built. In late 1995, Hubble took a break from looking at the usual
                    assortment of stars, planets and nebulae, and instead devoted ten continuous days to staring at a
                    tiny, non-descript part of the sky in the constellation of Ursa Major. Hubble's field of view is
                    minuscule each exposure covers an area on the sky about 1/30th the diameter of the full moon in
                    size. And this particular little patch of the sky had been carefully chosen to be as uninteresting
                    as possible, containing just a few faint stars.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-1">
                    So what did Hubble see at the end of this mammoth 10-day exposure? A handful of faint stars, of
                    course. But filling the spaces between these stars was a riot of smudges and whorls, all of
                    different colors and sizes. If you look closely enough at the image, the dark gaps between these
                    faint smudges are filled with yet fainter swirls and streaks. And between these can be seen even
                    fainter, barely detectable blurs of distant light. In fact, this supposedly blank part of the sky
                    hardly has any dark sky at all in it this little window onto the heavens suggests that the Universe
                    is filled with light.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-2">
                    What are these smudges? Appropriately, Edwin Hubble himself provided the answer more than eighty
                    years ago, when he showed that each of these faint patches was an entire galaxy, each containing
                    hundreds of billions of stars.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-3">
                    Billions of stars! We can try, perhaps fruitlessly, to put this in perspective. Our own Sun, an
                    ordinary star, is 1.4 million kilometers across, and weighs 2000 trillion trillion tons. The Sun is
                    a member of the Milky Way Galaxy, which can be seen on a dark night as a silvery band running
                    through the sky. The Milky Way contains about 400 billion other stars of various temperatures and
                    sizes and spans a million trillion kilometers from side to side. The Milky Way is itself quite
                    unremarkable - it is dwarfed by its neighbour, the Andromeda Galaxy, which sits 20 million trillion
                    km away, and contains another trillion stars. And the Milky Way and Andromeda are both part of a
                    much larger grouping called the Virgo Supercluster, which consists of thousands of galaxies spread
                    across two billion trillion km of interstellar space.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-4">
                    And so we can return to Hubble's "Deep Field", the 10-day exposure of what looked like nothing, now
                    realizing that every smudge and speck in this image is an entire galaxy, filled with billions of
                    stars and trillions of planets. Perhaps every galaxy harbours a myriad of sentient civilizations,
                    each looking up at the sky with their own telescopes, and marveling at the spectacle. And all this
                    in a patch of the sky smaller than your fingernail.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-5">
                    If the Hubble Deep Field were nothing more than a work of art illustrating a tiny part of the night
                    sky, it would still be an incredible image, because it reveals the unimaginable scale of the
                    Universe in which we live.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-6">
                    But to the scientist, it shows so much more.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-7">
                    The light that these galaxies emit, and which is eventually received by the Hubble telescope,
                    travels at a specific finite velocity, the cosmic speed limit of 1079 million km per hour. It thus
                    takes time for this light to reach us. And this means not just a slight delay, like a conversation
                    on a bad phone line, but a wait of billions of years. We have no way of knowing what these galaxies
                    look like now but only how they appeared when the light that we receive now began its journey.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-8">
                    The sky is a time machine, and the galaxies in the Hubble Deep Field are what our Milky Way might
                    have looked like when it was much younger. Using the smudges and colors in the Hubble Deep Field,
                    astronomers can understand how galaxies, the building blocks of the Universe, are formed and evolve.
                    There are few questions more profound than how we got here - through the images taken by Hubble, we
                    have gone a long way toward answering this question.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-9">
                    Even hardened scientists can't help but feel dwarfed and humbled by contemplating such issues. The
                    Universe is bigger than we can imagine, older than we can contemplate, and more complex than we can
                    conceive. All our dreams and fears, our times of triumph and of sorrow, our sense of history and our
                    hopes for the future, are utterly irrelevant and insignificant when contrasted to the colossal dance
                    that galaxies play out as they drift and spin through billions of years. Our world could end
                    tomorrow and the stars would not notice.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-10">
                    In some sense this realization is devastating - regardless of what humanity might ever achieve, the
                    Hubble Deep Field shows that we don't really matter that much. But to me the Deep Field is also a
                    source of comfort, and of exhilaration.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-11">
                    I feel comfort, because no matter what mistakes we make or calamities we suffer, the night sky will
                    still be there. If we lose a loved one or find ourselves in financial difficulties, the stars will
                    still shine. If we are lonely or sad or afraid, galaxies will still fill the sky. As much as life is
                    filled with fleeting opportunities and uncertainty, as we are at times overwhelmed by the feeling
                    that the years are slipping by and that there is not enough time to do the things we want to do, the
                    Universe patiently, irresistibly, evolves, and nothing we can do can change it. There is one great
                    certainty in life, even if we have to look deep into the night sky with an Earth-orbiting telescope
                    to find it.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-12">
                    And I feel exhilaration, because our ability to contemplate and to calculate what's out there surely
                    signifies the triumph of pure thought. Our knowledge of geology comes from digging up rocks and
                    looking at their composition and structure. Our understanding of biology is derived from dissecting
                    organs and tissue, and by making cells divide in a Petri dish. And our view of chemistry comes from
                    mixing chemicals in a test tube, or from boiling, freezing, or purifying various compounds.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-13">
                    But astronomy is very different. We will never travel to these stars. We will never scoop out a
                    sample of a star to weigh; we can never put a galaxy on a bench and poke it or prod it; we can never
                    create a black hole or a supernova or a star cluster in the lab and see what happens if we heat it
                    up or dissolve it in water.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-right-fast-delayed-14">
                    Instead, all we can do is watch. We collect incredibly weak glints of light from unimaginably far
                    away, and carefully measure their brightness and color, but those are the only clues we will ever
                    get as to how the Universe works.
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="translate-in-from-left-fast-delayed-15">
                    For the full text of the essay: https://bryangaensler.net/heavens.html
                </h5>
            </div>
        </div>

        <div class="row">
            <div class="card bg-dark col-12">
                <img class="card-img translate-in-from-bottom" src="assets/img/STScI-01EVTAT56XS4RN4CPSEVPM0TGF.jpg"
                    alt="">
                <div class="card-img-overlay d-flex align-items-start justify-content-end">
                    <div class="card-text d-none d-md-block ms-3">
                        <p class="col-12 col-lg-12 translate-in-from-right">The Hubble Deep Field Image, 1995</p>
                    </div>
                </div>
            </div>
            <div class="row d-md-none">
                <div class="col">
                    <p class="translate-in-from-right text-center">The Hubble Deep Field Image, 1995</p>
                </div>
            </div>
        </div>

    </div>

</div>-->