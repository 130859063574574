import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { BrowserModule, Meta } from '@angular/platform-browser';
import {
  NgModule
} from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './template/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './template/footer/footer.component';
import { ContactComponent } from './template/contact/contact.component';
import { TranslateService } from './service/translate.service';
import { UnderConstructionComponent } from './template/under-construction/under-construction.component';
import { NavlinksComponent } from './template/navlinks/navlinks.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { AstroPhotoComponent } from './template/astro-photo/astro-photo.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { M82ApgpComponent } from './pages/gallery/m82-apgp/m82-apgp.component';
import { M51DscComponent } from './pages/gallery/m51-dsc/m51-dsc.component';
import { CasaDscComponent } from './pages/gallery/casa-dsc/casa-dsc.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { AboutComponent } from './pages/about/about.component';
import { RunningGroupProjectComponent } from './pages/tutorials/running-group-project/running-group-project.component';
import { PreprocessingGroupProjectComponent } from './pages/tutorials/preprocessing-group-project/preprocessing-group-project.component';
import { ProcessingGroupProjectComponent } from './pages/tutorials/processing-group-project/processing-group-project.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PrintsComponent } from './pages/prints/prints.component';
import { M31Component } from './pages/gallery/m31/m31.component';
import { GKPerseiComponent } from './pages/gallery/gkpersei/gkpersei.component';
import { OrderComponent } from './template/order/order.component';
import { HdfComponent } from './pages/hdf/hdf.component';
import { AdfNorthComponent } from './pages/adf-north/adf-north.component';
import { AdfSouthComponent } from './pages/adf-south/adf-south.component';
import { HdfImageComponent } from './pages/image/hdf-image/hdf-image.component';
import { AdfNorthImageComponent } from './pages/image/adf-north-image/adf-north-image.component';
import { AdfSouthImageComponent } from './pages/image/adf-south-image/adf-south-image.component';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
      // Optional Step: Do some stuff with the url if needed.

      // If you lower it in the optional step 
      // you don't need to use "toLowerCase" 
      // when you pass it down to the next function
      return super.parse(url.toLowerCase()); 
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    ContactComponent,
    UnderConstructionComponent,
    NavlinksComponent,
    GalleryComponent,
    AstroPhotoComponent,
    M82ApgpComponent,
    M51DscComponent,
    CasaDscComponent,
    NotFoundComponent,
    TutorialsComponent,
    AboutComponent,
    RunningGroupProjectComponent,
    PreprocessingGroupProjectComponent,
    ProcessingGroupProjectComponent,
    BlogComponent,
    PrintsComponent,
    M31Component,
    GKPerseiComponent,
    OrderComponent,
    HdfComponent,
    AdfNorthComponent,
    AdfSouthComponent,
    HdfImageComponent,
    AdfNorthImageComponent,
    AdfSouthImageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [
    TranslateService,Meta,
    {
      provide: DefaultUrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
