import { Component } from '@angular/core';
import jsonRows from '../../../locale/data/adf-south.json';

@Component({
  selector: 'app-adf-south',
  templateUrl: './adf-south.component.html',
  styleUrls: ['./adf-south.component.sass']
})
export class AdfSouthComponent {

  rows: any = jsonRows;
}
