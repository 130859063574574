import { Component } from '@angular/core';
import jsonRows from '../../../locale/data/adf-north.json';

@Component({
  selector: 'app-adf-north',
  templateUrl: './adf-north.component.html',
  styleUrls: ['./adf-north.component.sass']
})
export class AdfNorthComponent {

  rows: any = jsonRows;
}
