<nav id="navbar" class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container">
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" routerLinkActive="active" routerLink="/home">
      <i class="fa fa-fw fa-house" style="height: 20px;"></i>&nbsp;ADF
      <!-- <img class="img-responsive d-inline-block align-text-top" src="assets/img/dsclogo/xs-logo/dsc-logo32x32.png" height="24px"> -->
    </a>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/hdf"><i class="fa fa-fw fa-satellite" style="height: 20px;"></i>&nbsp;Hubble Deep Field</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/adf-north"><i class="fa fa-fw fa-earth-americas" style="height: 20px;"></i>&nbsp;Amateur Deep Field North</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/adf-south"><i class="fa fa-fw fa-earth-oceania" style="height: 20px;"></i>&nbsp;Amateur Deep Field South</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/about"><i class="fa fa-fw fa-info-circle" style="height: 20px;"></i>&nbsp;About</a>
        </li>
      </ul>
    </div>

  </div>
</nav>