import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { HdfComponent } from './pages/hdf/hdf.component';
import { AdfNorthComponent } from './pages/adf-north/adf-north.component';
import { AdfSouthComponent } from './pages/adf-south/adf-south.component';
import { HdfImageComponent } from './pages/image/hdf-image/hdf-image.component';
import { AdfNorthImageComponent } from './pages/image/adf-north-image/adf-north-image.component';
import { AdfSouthImageComponent } from './pages/image/adf-south-image/adf-south-image.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
// import { GalleryComponent } from './pages/gallery/gallery.component';
// import { TutorialsComponent } from './pages/tutorials/tutorials.component';
// import { RunningGroupProjectComponent } from './pages/tutorials/running-group-project/running-group-project.component';
// import { PreprocessingGroupProjectComponent } from './pages/tutorials/preprocessing-group-project/preprocessing-group-project.component';
// import { ProcessingGroupProjectComponent } from './pages/tutorials/processing-group-project/processing-group-project.component';
import { AboutComponent } from './pages/about/about.component';
// import { M82ApgpComponent } from './pages/gallery/m82-apgp/m82-apgp.component';
// import { M51DscComponent } from './pages/gallery/m51-dsc/m51-dsc.component';
// import { M31Component } from './pages/gallery/m31/m31.component';
// import { GKPerseiComponent } from './pages/gallery/gkpersei/gkpersei.component';
// import { CasaDscComponent } from './pages/gallery/casa-dsc/casa-dsc.component';
// import { BlogComponent } from './pages/blog/blog.component';
// import { PrintsComponent } from './pages/prints/prints.component';


const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'hdf', component: HdfComponent},
  {path: 'adf-north', component: AdfNorthComponent},
  {path: 'adf-south', component: AdfSouthComponent},
  {path: 'image/hdf', component: HdfImageComponent},
  {path: 'image/adf-north', component: AdfNorthImageComponent},
  {path: 'image/adf-south', component: AdfSouthImageComponent},
  // {path: 'gallery', component: GalleryComponent},
  // {path: 'blog', component: BlogComponent},
  // {path: 'prints', component: PrintsComponent},
  // {path: 'tutorials', component: TutorialsComponent},
  // {path: 'tutorials/running-group-project', component: RunningGroupProjectComponent},
  // {path: 'tutorials/preprocessing-group-project', component: PreprocessingGroupProjectComponent},
  // {path: 'tutorials/processing-group-project', component: ProcessingGroupProjectComponent},
  {path: 'about', component: AboutComponent},
  // {path: 'm82', component: M82ApgpComponent},
  // {path: 'm82/:version', component: M82ApgpComponent},
  // {path: 'm51', component: M51DscComponent},
  // {path: 'm31', component: M31Component},
  // {path: 'm31/:version', component: M31Component},
  // {path: 'gkpersei', component: GKPerseiComponent},
  // {path: 'gkpersei/:version', component: GKPerseiComponent},
  // {path: 'casa', component: CasaDscComponent},
  // {path: 'casa/:version', component: CasaDscComponent},
  
  {path: '404', component: NotFoundComponent},
  {path: '**', pathMatch: 'full', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
